import React from "react";

const Hero = () => {
  return (
    <div className="hero" id="top">
      <div className="hero-text">
        <h1>Jennifer Gong</h1>
        <h3>Software Engineer</h3>
        <h4>New York City</h4>
      </div>
    </div>
  );
};

export default Hero;
