export const skillsData = [
  { name: "Javascript", icon: "devicon-javascript-plain" },
  { name: "Python", icon: "devicon-python-plain" },
  { name: "Ruby", icon: "devicon-ruby-plain" },
  { name: "CSS3", icon: "devicon-css3-plain" },
  { name: "HTML5", icon: "devicon-html5-plain" },
  { name: "TypeScript", icon: "devicon-typescript-plain" },
  { name: "React", icon: "devicon-react-original" },
  { name: "Redux", icon: "devicon-redux-original" },
  { name: "jQuery", icon: "devicon-jquery-plain" },
  { name: "PostgreSQL", icon: "devicon-postgresql-plain" },
  { name: "Django", icon: "devicon-django-plain" },
  { name: "Rails", icon: "devicon-rails-plain" },
  { name: "Node.js", icon: "devicon-nodejs-plain" },
  { name: "Express.js", icon: "devicon-express-original" },
  { name: "Sequelize", icon: "devicon-sequelize-plain" },
  { name: "Git", icon: "devicon-git-plain" },
  { name: "Github", icon: "devicon-github-plain" },
  { name: "Heroku", icon: "devicon-heroku-original" },
];
